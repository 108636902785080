.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.progress-section {
  margin: 10px 0;
  color: white;
  display: block;
  text-align: left;
}

.progress-bar-container {
  width: 100%;
  background-color: #f3f3f3;
  margin-top: 10px;
}

.progress-bar {
  height: 30px;
  width: 0%;
  background-color: #4caf50; /* Change this to any color you like */
  text-align: center;
  line-height: 30px;
  color: white;
}

/* styles.css */

.dark-background {
  display: none;
  position: fixed;
  z-index: 999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
}

.dark-background.visible {
  display: block;
}

/* Add any other styles you need for your application */
._loading_overlay_wrapper._loading_overlay_wrapper--active {
  position: absolute !important;
  width: 100% !important;
  height: 100vh !important;
  top: 0 !important;
  z-index: 9999 !important;
}
