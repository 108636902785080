@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  /* background: #593f3f; */
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  /* padding: 0 20px 100px; */
}

.Switcher {
  position: relative;
  display: flex;
  border-radius: 2em;
  box-shadow: inset 0 0 0 1px;
  overflow: hidden;
  cursor: pointer;
  -webkit-animation: r-n 0.5s;
          animation: r-n 0.5s;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  font-size: 12px;
  will-change: transform;
}
.Switcher__checkbox:checked + .Switcher {
  -webkit-animation-name: r-p;
          animation-name: r-p;
}

.Switcher::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 200%;
  border-radius: 0px;
  background-color: lightgray;
  transform: translateX(-75%);
  transition: transform 0.5s ease-in-out;
}
.Switcher__checkbox:checked + .Switcher::before {
  transform: translateX(25%);
}

.Switcher__trigger {
  position: relative;
  z-index: 1;
  padding: 1em 5em;
}
.Switcher__trigger::after {
  content: attr(data-value);
}
.Switcher__trigger::before {
  --i: var(--x);
  content: attr(data-value);
  position: absolute;
  color: black;
  transition: opacity 0.3s;
  opacity: calc((var(--i) + 1) / 2);
  transition-delay: calc(.3s * (var(--i) - 1) / -2);
}
.Switcher__checkbox:checked + .Switcher .Switcher__trigger::before {
  --i: calc(var(--x) * -1);
}
.Switcher__trigger:nth-of-type(1)::before {
  --x: 1;
}
.Switcher__trigger:nth-of-type(2)::before {
  --x: -1;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.boxData {
  display: flex;
  flex: 1;
  margin-top: 30px;
  align-items: center;
  flex-wrap: wrap;
  overflow: hidden;
  perspective: 750px;
}

.h1 {
  margin-top: 40px;
  font-size: 25px;
  padding-bottom: 10px;
  border-bottom: 1px solid rgb(79, 98, 148);
}

.form {
  max-width: 800px;
  margin: 0 auto;
}

.p {
  color: #bf1650;
  text-align: center;
}

.input {
  display: block;
  box-sizing: border-box;
  width: 100%;
  border-radius: 4px;
  border: 1px solid #593f3f;
  padding: 10px 15px;
  margin-bottom: 10px;
  font-size: 14px;
}

.inputTextarea {
  display: block;
  box-sizing: border-box;
  width: 100%;
  border-radius: 4px;
  border: 1px solid white;
  padding: 10px 15px;
  margin-bottom: 10px;
  font-size: 14px;
  min-height: 150px; /* Adjust the height value as needed */
}

.label,
section > label {
  line-height: 2;
  text-align: left;
  display: block;
  margin-bottom: 13px;
  margin-top: 20px;
  color: #593f3f;
  font-size: 14px;
  font-weight: 200;
}

.button {
  background: #ec5990;
  color: white;
  text-transform: uppercase;
  border: none;
  margin-top: 40px;
  padding: 20px;
  font-size: 16px;
  font-weight: 100;
  letter-spacing: 10px;
  display: block;
  appearance: none;
  border-radius: 4px;
  width: 100%;
}

.buttonBlack {
  background: black;
  border: 1px solid white;
}

.App {
  width: 100%;
  margin: 0px auto;
}

.container {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;
}

.subcontainer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;
}

.counter {
  font-weight: 400;
  background: white;
  color: black;
  padding: 10px 15px;
  border-radius: 4px;
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 9999999;
  border: 1px solid rgb(79, 98, 148);
  box-shadow: 0 0 4px rgb(79, 98, 148);
}

.MuiTypography-body1 {
  font-size: 1rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  color: white;
}

.success-message {
  color: #4caf50; /* Green */
  margin-top: 20px;
  /* other styling as needed */
}

.error-message {
  color: #f44336; /* Red */
  margin-top: 20px;
  /* other styling as needed */
}

.progress-section {
  margin: 10px 0;
  color: white;
  display: block;
  text-align: left;
}

.progress-bar-container {
  width: 100%;
  background-color: #f3f3f3;
  margin-top: 10px;
}

.progress-bar {
  height: 30px;
  width: 0%;
  background-color: #4caf50; /* Change this to any color you like */
  text-align: center;
  line-height: 30px;
  color: white;
}
